import React from "react";

// External
import styled, { css } from "styled-components";
import { Element } from "react-scroll";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// Internal
import SplashText from "../../components/SplashText";
import Button from "../../components/form/Button";
import { breakpoints, fontFamily, spacing, colors } from "../../../theme";
import { PROJECTS, PROJECT_NAMES } from "../../utils/constants/projects";

// Styles
const Section = styled(Element)`
  // height: 100vh;
  padding: 20px 0px 60px;
  background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 0 150px;
  }
`;

const Heading = styled.h1`
  font-family: ${fontFamily.extraBold};
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -1%;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }
`;

const SkillCardList = styled.div`
  margin-top: ${spacing(9)};
  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: space-evenly;
    margin-top: 120px;
  }
`;
const SkillCard = styled.div`
  background: ${props => props.bgColor};
  border-radius: 24px;
  width: 80%;
  margin: 0 auto 12px;
  padding: 27px;
  // width: 335px;
  // height: 237px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 42px 112px;
    width: 27%;
    max-width: 503px;
    margin: 0;
  }
`;
const SkillCardIcon = styled.div`
  padding: 16px 0;

  & svg {
    transform: scale(1.5);
    padding-left: 16px;
    margin-bottom: 32px;
  }
`;
const SkillCardHeading = styled.div`
  margin: 4px 0;
  font-size: 20px;
  font-family: ${fontFamily.bold};
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 36px;
  }
`;
const SkillCardDescription = styled.div`
  margin-bottom: 12px;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 20px;
    width: 98%;
    margin-top: 8px;
  }
`;

const ProjectList = styled.div`
  width: 94%;
  margin: 0 auto;
  margin-top: ${spacing(9)};
  // max-width: 1366px;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 120px;
  }
`;

const ProjectCoverSection = styled.div`
  position: relative;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    ${props =>
      props.isAlternate
        ? css`
            margin-left: 70px;
          `
        : css`
            margin-right: 70px;
          `}
  }

  @media only screen and (min-width: 1400px) {
    ${props =>
      props.isAlternate
        ? css`
            margin-left: 100px;
          `
        : css`
            margin-right: 100px;
          `}
  }
  @media only screen and (min-width: 1800px) {
    ${props =>
      props.isAlternate
        ? css`
            margin-left: 150px;
          `
        : css`
            margin-right: 150px;
          `}
  }
`;

const ProjectInfoSection = styled.div`
  margin-top: 2rem;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin-top: 0;
    // width: 350px;
  }
`;

const ProjectMetaText = styled.span`
  font-size: 12px;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 14px;
  }
`;

const ProjectRoles = styled.span`
  font-size: 12px;
  margin-block: 1rem 6rem;
  display: block;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 14px;
    margin-block: 1rem 2rem;
  }
`;

const ProjectHeading = styled.h3`
  margin-block: 6px;
  font-family: ${fontFamily.bold};
  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: 400px;
    font-size: 24px;
  }
`;

const StyledButton = styled(Button)`
  padding-block: 12px;
  font-size: 16px;
  margin-block: 6px;
  width: 100%;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: auto;
    margin-inline: 4px 8px;
    font-size: 12px;
  }
`;

const VideoContainer = styled.div`
  width: ${props => (props.isMobileCover ? "96%" : "100%")};
  border: 4px solid white;
  border-radius: 6px !important;
  overflow: hidden;
  box-sizing: border-box;
  background-image: linear-gradient(180deg, #fff, #fff);
  box-shadow: 17px 17px 48px 0 rgb(0 0 0 / 12%);

  @media only screen and (min-width: ${breakpoints.tablet}) {
    width: clamp(400px, 28vw, 500px);
  }

  //   margin: 12rem 0 0;
`;

const VideoContainerMobile = styled(VideoContainer)`
  z-index: 2;
  position: absolute;

  width: 70px;
  height: 135.516px;
  right: 0px;
  bottom: -20px;
  margin: 0;

  // @media only screen and (min-width: ${breakpoints.tablet}) {
  //   width: 120px;
  //   height: 248.203px;
  //   right: -15px;
  //   bottom: -30px;
  // }
`;

const Video = styled.video`
  //   width: 100%;
  height: 102%;
  border-radius: 6px !important;
  transform: scale(1.01);
  // box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  // @media only screen and (min-width: ${breakpoints.tablet}) {
  //   border-radius: 12px;
  // }
`;

const ProjectCardContainer = styled.div`
  background-color: ${props => props.bgColor};
  margin-block: 12px;
  width: 100%;
  padding: 30px 24px;
  border-radius: 12px;
  box-sizing: border-box;
  img,
  video {
    width: 100%;
    height: auto;
  }
  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: ${props => (props.isAlternate ? "row-reverse" : "row")};
    align-items: flex-start;
    justify-content: center;
    padding: 80px 36px;
  }
`;

const ButtonRow = styled.div`
  @media only screen and (min-width: ${breakpoints.tablet}) {
    display: flex;
  }
`;

const StyledAniLink = styled(AniLink)`
  text-decoration: none;
  // color: ${colors.black};
`;

const getProjectCardColor = name => {
  if (name === PROJECT_NAMES.CONDEXO_PAY) {
    return "#F0F8FF";
  } else if (name === PROJECT_NAMES.CONDEXO_PROPERTY_MANAGEMENT) {
    return "#F0FFFD";
  } else if (name === PROJECT_NAMES.HARMINDRA_INTERNATIONAL) {
    return "#F0F6FF";
  }
};

const ProjectCard = ({ data, index }) => {
  console.log({ data });
  const handleReadCaseStudyClick = () => {};
  const handleViewProjectClick = () => {
    window.open(data.links.project, "_blank");
  };
  return (
    <ProjectCardContainer
      isAlternate={index % 2 === 0}
      bgColor={getProjectCardColor(data.name)}
    >
      <ProjectCoverSection
        isAlternate={index % 2 === 0}
        reduceVideoSize={data.name === PROJECT_NAMES.CONDEXO_PAY}
      >
        <VideoContainer isMobileCover={data.media.cover.mobile}>
          <Video autoPlay muted loop src={data.media.cover.desktop} />
        </VideoContainer>
        {data.media.cover.mobile && (
          <VideoContainerMobile>
            <Video autoPlay muted loop src={data.media.cover.mobile} />
          </VideoContainerMobile>
        )}
      </ProjectCoverSection>
      <ProjectInfoSection>
        <ProjectMetaText>
          {data.name} &nbsp;|&nbsp; {data.client.country} &nbsp;|&nbsp;
          {data.year.length > 1
            ? `${data.year[0]}-${data.year[1]}`
            : data.year[0]}
        </ProjectMetaText>
        <ProjectHeading>{data.heading}</ProjectHeading>
        <ProjectRoles>{data.roles.join(", ")}</ProjectRoles>
        <ButtonRow>
          <StyledAniLink cover bg={colors.black} to={`/projects/${data.slug}`}>
            <StyledButton size="small">Read Case Study</StyledButton>
          </StyledAniLink>
          <StyledButton
            size="small"
            variant="outlined"
            onClick={handleViewProjectClick}
          >
            View Project
          </StyledButton>
        </ButtonRow>
      </ProjectInfoSection>
    </ProjectCardContainer>
  );
};

const ProjectsSection = () => {
  return (
    <Section name="projects" as="section">
      <Heading>
        <SplashText variant="peach">Projects</SplashText>
      </Heading>
      <ProjectList>
        {PROJECTS.map((project, index) => (
          <ProjectCard key={project.name} data={project} index={index} />
        ))}
      </ProjectList>
    </Section>
  );
};

export default ProjectsSection;
