import {
  RECOMMENDATION_NEHA_KAPUR,
  RECOMMENDATION_SUNNY_TYAGI,
  RECOMMENDATION_ELISA_FIORENZANI,
  RECOMMENDATION_NAVEEN_DHARNI,
  RECOMMENDATION_DAVINDER_SINGH,
} from "./recommendations";
export const _CLIENT_CONDEXO = {
  name: "Condexo",

  reviews: [
    RECOMMENDATION_ELISA_FIORENZANI,
    RECOMMENDATION_NEHA_KAPUR,
    RECOMMENDATION_NAVEEN_DHARNI,
    RECOMMENDATION_SUNNY_TYAGI,
  ],
};

export const CLIENT_CONDEXO_UK = {
  ..._CLIENT_CONDEXO,
  country: "UK",
};

export const CLIENT_CONDEXO_ITALY = {
  ..._CLIENT_CONDEXO,
  country: "Italy",
};

export const CLIENT_HARMINDRA_INTERNATIONAL = {
  name: "Harmindra International",
  country: "India",
  reviews: [RECOMMENDATION_DAVINDER_SINGH],
};

export const PROJECT_NAMES = {
  CONDEXO_PAY: "Condexo Pay",
  CONDEXO_PROPERTY_MANAGEMENT: "Condexo Property Management",
  HARMINDRA_INTERNATIONAL: "Harmindra International",
};

export const PROJECT_CONDEXO_PAY = {
  year: ["2019", "2021"],
  name: PROJECT_NAMES.CONDEXO_PAY,
  slug: "condexo-pay",
  heading: "Simplifying & Securing Online Payment through All-In-One App",
  client: CLIENT_CONDEXO_ITALY,
  media: {
    cover: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038674/projects/condexo-pay/condexo-pay__landing-page--old_zxl90m_gq9t2z.mp4",
      mobile:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038638/projects/condexo-pay/condex-pay__landing-page--mobile_dfw2r8_mpvl3n.mp4",
    },
    myPayments: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038599/projects/condexo-pay/condexo-pay__my-payments_crskpm_xlqpif.mp4",
      mobile: "",
    },
    bolletini: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038634/projects/condexo-pay/condexo-pay__bolettini-flow_rmytnt_vn8uf3.mp4",
      mobile: "",
    },
    giftCard: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038626/projects/condexo-pay/condexo-pay__gift-card_nmzwxf_fqtwhi.mp4",
      mobile: "",
    },
  },
  links: {
    project: "https://pay.condexo.it/",
  },
  techStack: [
    "Javascript",
    "React",
    "Redux",
    "Redux Saga",
    "Material UI",
    "Stripe",
  ],
  industry: "FinTech",
  roles: ["React Developer"],
  services: ["Front-end Development"],
};

export const PROJECT_CONDEXO_PROPERTY_MANAGEMENT = {
  year: ["2019", "2021"],
  name: PROJECT_NAMES.CONDEXO_PROPERTY_MANAGEMENT,
  slug: "condexo-property-management",
  heading:
    "Reducing Landlord's burden of handling properties using Property Management Web App",
  client: CLIENT_CONDEXO_UK,
  media: {
    cover: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039284/projects/condexo-property-management/condexo-property__dashboard_fso9cu.mp4",
      mobile:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039329/projects/condexo-property-management/condexo-property__dashboard--mobile_nz3wn6.mp4",
    },
    property: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038639/projects/condexo-property-management/condexo-pay__property_kqopqo_itjn26.mp4",
      mobile: "",
    },
    tenancy: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038690/projects/condexo-property-management/condexo-pay__create-tenancy_jq9qvl_ahn2ls.mp4",
      mobile: "",
    },
    calendar: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039494/projects/condexo-property-management/condexo-property__calendar_mbspuo.mp4",
      mobile: "",
    },
    documents: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039583/projects/condexo-property-management/condexo-property__documents_k2yytp.mp4",
      mobile: "",
    },
    accounts: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038682/projects/condexo-property-management/condexo-property__accounts_s542wo_owaoka.mp4",
      mobile: "",
    },

    myPayments: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038599/projects/condexo-pay/condexo-pay__my-payments_crskpm_xlqpif.mp4",
      mobile: "",
    },
    bolletini: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038634/projects/condexo-pay/condexo-pay__bolettini-flow_rmytnt_vn8uf3.mp4",
      mobile: "",
    },
    giftCard: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648038626/projects/condexo-pay/condexo-pay__gift-card_nmzwxf_fqtwhi.mp4",
      mobile: "",
    },
  },
  links: {
    project: "https://manager.condexo.co.uk/",
  },
  techStack: [
    "Javascript",
    "React",
    "Redux",
    "Redux Saga",
    "React Bootstrap",
    "Stripe",
  ],
  industry: "Real Estate",
  roles: ["React Developer"],
  services: ["Front-end Development"],
};

export const PROJECT_HARMINDRA_INTERNATIONAL = {
  year: ["2021"],
  name: PROJECT_NAMES.HARMINDRA_INTERNATIONAL,
  slug: "harmindra-international",
  heading:
    "Establishing online identity for a Manufacturing firm of India through Product Catalog website",
  client: CLIENT_HARMINDRA_INTERNATIONAL,
  media: {
    cover: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039786/projects/harmindra-international/harmindra-international__landing_u4zcsw.mp4",
      mobile:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039755/projects/harmindra-international/harmindra-international__landing--mobile_cpfkdx.mp4",
    },

    cms: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039763/projects/harmindra-international/harmindra-international__cms_nishph.mp4",
      mobile: "",
    },
    about: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039801/projects/harmindra-international/harmindra-international__about_d11ufk.mp4",
      mobile: "",
    },
    product: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039731/projects/harmindra-international/harmindra-international__product_br5oac.mp4",
      mobile: "",
    },
    categories: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039727/projects/harmindra-international/harmindra-international__categories_t5vacl.mp4",
      mobile: "",
    },
    photoShoot: {
      desktop:
        "https://res.cloudinary.com/parmeetsinghasija/video/upload/v1648039720/projects/harmindra-international/harmindra-internatinoal__photoshoot_sr6ugw.mp4",
      mobile: "",
    },
  },
  links: {
    project: "https://harmindrainternational.com/",
  },
  techStack: [
    "TypeScript",
    "Gatsby (React)",
    "Material UI",
    "Contentful CMS",
    "Figma",
  ],
  industry: "Manufacturing",
  roles: ["React Developer", "UI/UX Designer", "Copywriter"],
  services: ["Front-end Development", "UI/UX Design", "Copywriting", "CMS"],
};

export const PROJECT_CRYPLIST = {};

export const PROJECTS = [
  PROJECT_CONDEXO_PAY,
  PROJECT_CONDEXO_PROPERTY_MANAGEMENT,
  PROJECT_HARMINDRA_INTERNATIONAL,
];
